import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hiw',
  templateUrl: './hiw.component.html',
  styleUrls: ['./hiw.component.css']
})
export class HiwComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
